<template>
  <vx-card class="pl-3">
    <div class="notification-container">
      <vs-table
        ref="table"
        :sst="true"
        :data="notifications"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between py-4"
        >
          <vs-select
            placeholder="25"
            vs-multiple
            autocomplete
            v-model="dataTableParams.limit"
            :disabled="!serverResponded"
            class="mr-6"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in limitOptions"
              :clearable="false"
            />
          </vs-select>
          <vs-input
            icon="search"
            class="search-input"
            :disabled="!serverResponded"
            v-model="dataTableParams.search"
            @input="handleSearch"
            placeholder="Search..."
          />
        </div>

        <template slot="thead">
          <vs-th sort-key="type">Type</vs-th>
          <vs-th sort-key="title">Title</vs-th>
          <vs-th sort-key="message">Message</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th size="25%">Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :class="data[indextr].status === 'unread' ? 'unreadTr' : ''"
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
          >
            <vs-td :data="data[indextr].type">{{
              data[indextr].type | capitalize
            }}</vs-td>
            <vs-td :data="data[indextr].title">{{ data[indextr].title }}</vs-td>
            <vs-td :data="data[indextr].message">{{
              data[indextr].message
            }}</vs-td>
            <vs-td :data="data[indextr].status">{{
              data[indextr].status
            }}</vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              data[indextr].createdAt | date_formatter
            }}</vs-td>
            <vs-td :data="data[indextr].type">
              <vx-tooltip
                text="View Details"
                v-if="
                  data[indextr].type !== 'Admin Notification' &&
                    data[indextr].type !== 'Nurse Invitation'
                "
              >
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  @click="
                    viewDetailHandler(
                      data[indextr]._id,
                      data[indextr].type,
                      data[indextr].metaData
                    )
                  "
                  icon-pack="feather"
                  icon="icon-eye"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip
                text="Mark As Read"
                v-if="
                  data[indextr].type === 'Admin Notification' &&
                    data[indextr].status === 'unread'
                "
              >
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-book-open"
                  @click="readNotificationHandler(data[indextr]._id)"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>

              <vx-tooltip
                text="Mark As Read"
                v-if="
                  data[indextr].type === 'Nurse Invitation' &&
                    data[indextr].status === 'unread'
                "
              >
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-book-open"
                  @click="readNotificationHandler(data[indextr]._id)"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="flex justify-end">
        <span class="mr-2"></span>
        <pagination
          class="mt-3"
          :isFetching="!serverResponded"
          :hasPrev="hasPrev"
          :hasNext="hasNext"
          @handleOnChangePage="handleChangePage"/>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import Pagination from "@/views/general-component/pagination.vue";
import { debounce } from "lodash";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25
      },
      serverResponded: false,
      notifications: [],
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      noDataText: "Loading...",
      hasNext: false,
      hasPrev: false
    };
  },
  methods: {
    ...mapActions("general", ["fetchPaginatedNotifications"]),
    ...mapActions("monthlyCompliance", [
      "getMonthlyCompliance",
      "startCompliance",
    ]),
    getNotificationsList(options = {}) {
      let self = this;
      this.notifications = []
      this.noDataText = "Loading..."
      this.fetchPaginatedNotifications({
        ...self.dataTableParams,
        ...options
      }).then(res => {
        self.notifications = res.data.data.docs;
        self.hasNext = res.data.data.hasNext
        self.hasPrev = res.data.data.hasPrev
        self.serverResponded = true;
        self.noDataText = "You have no notifications."

        if (options.page) self.dataTableParams.page = options.page
      });
    },
    handleSearch: debounce(function (searchString) {
      this.serverResponded = false;
      this.$refs.table.currentx = 1;
      this.getNotificationsList({
        search: searchString,
        page: 1
      });
    }, 500),
    handleChangePage(page) {
      let payload = {
        next: true,
        id: this.notifications[this.notifications.length - 1]._id,
        page: this.dataTableParams.page + 1
      }

      if (page === 'prev') {
        payload = {
          prev: true,
          id: this.notifications[0]._id,
          page: this.dataTableParams.page - 1
        }
      }

      this.serverResponded = false;
      this.getNotificationsList(payload)
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getNotificationsList();
    },
    async viewDetailHandler(id, type, metaData) {
      const data = JSON.parse(metaData);
      if (
        type == "AHPRA Expiry Account Disabled" ||
        type == "AHPRA Expiry" ||
        type == "Indemnity Insurance Expiry Account Disabled" ||
        type == "Indemnity Insurance Expiry" ||
        type === "Updated Profile Approved" ||
        type === "Updated Profile Rejected"
      ) {
        this.$router.push("/nurse/profile");
      } else if (type == "Nurse Pending Treatment Notes") {
        this.$router.push(
          "/nurse/patients/" +
            data.patientId +
            "/treatment/" +
            data.checkInId +
            "?backRoute=nurseNotification"
        );
      } else if (type == "Treatment Outcome Updated") {
        this.$router.push(
          `/${this.$store.state.AppActiveUser.userType}/patients/${data.patientId}/treatment/${data.checkInId}?backRoute=nurseNotification`
        );
      } else if (type === "Clinic Join Request") {
        this.$router.push(
          `/${this.$store.state.AppActiveUser.userType}/clinics/${data.clinicId}/respond-clinic-join-request/${data.requestId}`
        );
      } else if (type === "New Compliance Check" || type === "Compliance Check - 7 Days remaining") {
        await this.startCompliance(data.complianceId);
        this.$router.push(
          `/org-owner/compliance/${data.complianceId}/review`
        )
      } else if (type === "Compliance Check is Due Today" || type === "Compliance Check requires changes" || type === "Compliance Check requires changes is Due Today") {
        this.$router.push(
          `/org-owner/compliance/${data.complianceId}/review`
        )
      } else if (type === "Compliance Check Failed" || type === "Compliance Check Missed") {
        this.$router.push(
          `/org-owner/compliance/`
        )
      }

      this.readNotification(id);
    },
    readNotificationHandler(notificationId) {
      this.$vs.loading();
      this.$store
        .dispatch("general/readNotifications", notificationId)
        .then(response => {
          for (var i in this.notifications) {
            if (this.notifications[i]._id == notificationId) {
              this.notifications[i].status = "read";
              break;
            }
          }
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    readNotification(id) {
      this.$store
        .dispatch("general/readNotifications", id)
        .then(response => {})
        .catch(err => console.log(err));
    }
  },
  watch: {
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getNotificationsList();
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getNotificationsList();
  }
};
</script>

<style lang="scss">
.notification-container {
  tr.unreadTr {
    background: #f0f8ff;
  }

  .search-input {
    .vs-input--input {
      border-radius: 20px;

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .03);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .03);
      }
    }

    .vs-con-input {
      .vs-input--icon {
        top: auto;
        left: 8px;
        border-right: none;
      }
    }
  }
}
</style>
